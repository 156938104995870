import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Group } from '@mantine/core';

import { authUserWithOAuth } from '@/store/entities/auth/actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const OAuthButtons = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('APPCON');

  const { oAuthClients } = useAppSelector((store) => store.auth);
  const handleOnClickOAuthClients = (client: string) => {
    dispatch(authUserWithOAuth({ clientName: client }));
  };

  return (
    <>
      <Box sx={{ color: 'gray', textTransform: 'uppercase' }} mb="lg" mt={'lg'}>
        <Divider label={t('or')} labelPosition="center" />
      </Box>

      <Group spacing={'xs'}>
        {oAuthClients.map((item) => (
          <Button
            fullWidth
            radius={4}
            onClick={() => handleOnClickOAuthClients(item.name)}
            leftIcon={
              <Box
                style={{ position: 'relative' }}
                w={20}
                dangerouslySetInnerHTML={{ __html: item.image_svg }}
              >
                {/*<img width={20} src={GoogleSVG} alt="" />*/}
                {/*{item.image_svg}*/}
              </Box>
            }
            // radius={10}
            styles={() => ({
              root: {
                backgroundColor: 'transparent',
                color: 'black',
                border: '1px solid #ced4da',
                height: 42,
                // paddingLeft: 20,
                // paddingRight: 20,
                '&:hover': {
                  backgroundColor: '#eeeeee',
                },
              },
              inner: {
                justifyContent: 'left',
              },
              leftIcon: {
                marginRight: 15,
              },
            })}
          >
            {t('Sign in with {{x}}', { x: item.display_name })}
          </Button>
        ))}
      </Group>
    </>
  );
};

export default OAuthButtons;
