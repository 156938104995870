import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import { classNames } from '@/fsd/shared/lib/classNames/classNames';

import cls from './Button.module.scss';

export enum ThemeButton {
  CLEAR = 'clear',
  DEFAULT = 'default',
}

export enum IconPositionButton {
  RIGHT = 'right',
  LEFT = 'left',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  theme?: ThemeButton;
  iconPosition?: IconPositionButton;
  icon?: ReactNode;
}

export const Button: FC<ButtonProps> = (props) => {
  const {
    className,
    children,
    theme = ThemeButton.DEFAULT,
    iconPosition = IconPositionButton.RIGHT,
    icon,
    ...otherProps
  } = props;

  const handleIconButton = () => {
    return (
      <div
        className={classNames(
          cls.iconButton,
          {
            [cls.iconClear]: !!icon && theme === ThemeButton.CLEAR,
            [cls.iconDefault]: !!icon && theme === ThemeButton.DEFAULT,
          },
          []
        )}
      >
        {icon}
      </div>
    );
  };

  return (
    <button
      className={classNames(
        cls.Button,
        {
          [cls.buttonWithIcon]: !!icon,
        },
        [className, theme && cls[theme]]
      )}
      {...otherProps}
    >
      {iconPosition === IconPositionButton.LEFT && icon && handleIconButton()}
      {children}
      {iconPosition === IconPositionButton.RIGHT && icon && handleIconButton()}
    </button>
  );
};
