import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Group, Pagination as MPagination, Select, Text } from '@mantine/core';

import NumberValuesFormat from '@/ui/NumberFormat/NumberValues/NumberValuesFormat';

import cls from './Pagination.module.scss';

interface IPagination {
  total: number | undefined;
  limit: number | undefined;
  currentPage: number | undefined;
  onChange?: (page: number) => void;
  disable: boolean;
  setPageSize?: (page: number) => void;
}

const Pagination: FC<IPagination> = ({
  limit = 10,
  currentPage = 1,
  total = 0,
  onChange,
  disable,
  setPageSize,
}) => {
  const { t } = useTranslation('APPCON');

  return total > 0 ? (
    <Group align={'center'} position="apart" pt={20} fz={14}>
      <Box className={cls.left}>
        {setPageSize && (
          <Group spacing={'xs'}>
            <Select
              w={50}
              styles={{
                input: {
                  paddingLeft: 8,
                  paddingRight: 15,
                  height: 26,
                  backgroundColor: 'transparent',
                },
              }}
              value={String(limit)}
              size={'xs'}
              onChange={(value) => setPageSize && setPageSize(Number(value))}
              data={[
                { value: '10', label: '10' },
                { value: '25', label: '25' },
                {
                  value: '50',
                  label: '50',
                },
              ]}
            />
            <Text fz={14}>{t('appcon040--46')}</Text>
          </Group>
        )}
        <Text>
          <Trans
            t={t}
            i18nKey={'appcon040-0T-4'}
            defaults={
              'Results from <currentBegin/> to <currentEnd/> out of <b><total/></b> results'
            }
            components={{
              currentBegin: <NumberValuesFormat value={(currentPage - 1) * limit + 1} />,
              currentEnd: (
                <NumberValuesFormat
                  value={currentPage * limit > total ? total : currentPage * limit}
                />
              ),
              total: <NumberValuesFormat value={total} />,
              b: <b />,
            }}
          />
        </Text>
      </Box>
      <Group spacing={'lg'}>
        <MPagination
          styles={() => ({
            item: {
              backgroundColor: 'transparent',
            },
          })}
          size="sm"
          page={currentPage}
          total={Math.ceil(total / limit)}
          onChange={!disable ? onChange : () => null}
        />
      </Group>
      {/*{setPageSize && (*/}
      {/*  <Group spacing={'xs'}>*/}
      {/*    <Select*/}
      {/*      w={50}*/}
      {/*      styles={{*/}
      {/*        input: {*/}
      {/*          paddingLeft: 8,*/}
      {/*          paddingRight: 15,*/}
      {/*          height: 26,*/}
      {/*        },*/}
      {/*      }}*/}
      {/*      value={String(limit)}*/}
      {/*      size={'xs'}*/}
      {/*      onChange={(value) => setPageSize && setPageSize(Number(value))}*/}
      {/*      data={[*/}
      {/*        { value: '10', label: '10' },*/}
      {/*        { value: '25', label: '25' },*/}
      {/*        {*/}
      {/*          value: '50',*/}
      {/*          label: '50',*/}
      {/*        },*/}
      {/*      ]}*/}
      {/*    />*/}
      {/*    <Text fz={14}>{t('appcon040--46')}</Text>*/}
      {/*  </Group>*/}
      {/*)}*/}
    </Group>
  ) : (
    <></>
  );
};

export default Pagination;
